
  .RedesContenido {
    background-color: rgb(4, 4, 14);
    height: auto;
  }

  .RedesContenido h1 {
    padding: 50px;
    padding-top: 180px;
    text-align: center;
    font-size: 40px;
    color: #fff;
  }
  .RedesContenido .redes a {
    color: #fff;
    text-decoration: none;
  }
  .RedesContenido .redes i {
    color: #fff;
  }




.redes {
    text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}
 .redes .red {

    text-decoration: none;
    background-color: rgb(156, 156, 212);
    border-radius: 10px;
    margin: 20px;
    text-align: center;
    padding: 20px 20px;
    font-size: 20px;
    width: 500px;
    height: auto;
  }


  @media screen and (max-width:800px){
    .redes .red {

        text-decoration: none;
        background-color: rgb(156, 156, 212);
        border-radius: 10px;
        margin: 20px;
        text-align: center;
        padding: 20px 20px;
        font-size: 20px;
        width: 300px;
        height: auto;
      }
    
}





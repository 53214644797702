body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
}

.App {
  text-align: center;
}

.Contenido {
  background-color: rgb(4, 4, 14);
  min-height: 99.9vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Contenido .c {
  display: flex;
  align-items: center;
  padding-top: 100px;
}

.Contenido .texto {
  text-align: left;
}
.Contenido h1 {
  color: #9ba7be;
  margin: 0;
}
.Contenido h3 {
  color: #7281a5;
  font-size: 25px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
}

.Contenido a {
  text-decoration: none;
  color: #fff;
  background-color: #535d74;
  padding: 5px 15px;
  border-radius: 10px;
}

.Contenido img {
  padding: 50px;
  padding-right: 100px;
  height: 250px;
}

.Barra {
  background-color: rgba(4, 4, 14, 0.897);
  backdrop-filter: blur(8px);
  top: 54px;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 110px;
  border-radius: 20px;
  width: 1800px;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
}
.Barra button {
  transition: all 0.5s;
  background-color: transparent;
  color: #d1d4d6;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
  font-size: 16px;
  border: 0;
}
.Barra button:hover {
  transition: all 0.5s;
  color: rgb(255, 255, 255);
  transform: translateY(-4px);
}
.Barra img {
  height: 50px;
  padding: 20px;
  animation-name: logo_barras;
  animation-iteration-count: infinite;
  animation-duration: 20s;
}

@keyframes logo_barra {
  0% {transform: rotateZ(0deg);}
  60% {transform: rotateZ(0deg);}
  70% {transform: rotateZ(-60deg);}
  80% {transform: rotateZ(-60deg);}
  100% {transform: rotateZ(0deg);}
}




.SobreNosotros {
  background-color: rgb(4, 4, 14);
  min-height: auto;
  text-align: center;
  padding: 160px;
  padding-bottom: 100px;
  font-size: calc(10px + 2vmin);
  color: white;
}




.SobreNosotros .content {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}


.SobreNosotros .perfil {
  display: flex;
  align-items: center;
}

.SobreNosotros .p_t {
  padding-top: 100px;
}

.SobreNosotros .perfil p {
  font-size: 20px;
}

.SobreNosotros .texto {
  text-align: left;
}

.RRSS {
  text-decoration: none;
  background-color: rgb(41, 41, 133);
  padding: 5px 15px;
  margin: 5px;
  font-size: 20px;
  border-radius: 10px;
  color: rgb(255, 255, 255);
}

.SobreNosotros .texto p {
  padding-bottom: 15px;
}
.SobreNosotros .perfil img {
  height: 300px;
  padding: 30px;
}



.Podcast {
  background-color: rgb(4, 4, 14);
  min-height: auto;
  text-align: center;
  padding-top: 160px;
  padding-bottom: 100px;
  padding-left: 160px;
  padding-right: 160px;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.Podcast .info {
  background-color: #535d74;
  width: auto;
  margin: 20px;
  border-radius: 10px;

  align-items: center;
  justify-content: left;
  display: flex;
}

.Podcast .info img {
  height: 40px;
  border-radius: 10px;
  background-color: #0e121b;
  padding: 20px;
  margin: 20px;
}


.Podcast .texto {
  text-align: left;
}
.Podcast .texto h3 {
  margin: 0;
  padding: 0;
}
.Podcast .texto a {
  
  font-size: 15px;
  text-decoration: none;
  color: #fff;
}

.Podcast .ver {
  background-color: #373c47;
  padding: 5px 15px;
  border-radius: 10px;
}
.Podcast .verDis {
  background-color: #373c47;
  opacity: 60%;
  cursor: no-drop;
  padding: 5px 15px;
  border-radius: 10px;
}
@media only screen and (max-width: 768px) {


  .Barra {
    zoom: 60%;
    top: 55px;
    background-color: rgba(4, 4, 14, 0.675);
    backdrop-filter: blur(8px);
    width: 1800px;
  }


  .Contenido .c { display: block; }
  .Contenido .texto { text-align: center; }
  .Contenido img { padding: 0; }
  .SobreNosotros { padding: 20px; padding-top: 120px; }
  
  .SobreNosotros .perfil {
    display: block;
  }
  .SobreNosotros .perfil img {
    height: 300px;

  }

  .Podcast { padding-left: 20px;  padding-right: 20px; }

}













.Footer{
  width: 100%;
  background-color: rgb(4, 4, 14);
}
.Footer .grupo-1{
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap:50px;
  padding: 45px 0px;
}
.Footer .grupo-1 .box figure{
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.Footer .grupo-1 .box figure img{
  width: 250px;
}
.Footer .grupo-1 .box h2{
  color: #ffffff;
  margin-bottom: 25px;
  font-size: 20px;
}
.Footer .grupo-1 .box #DatosMas {
  color: #ffffffae;
  text-decoration: none;
  padding: 10px;
  margin-bottom: 10px;
}

.Footer .grupo-1 .box #DatosMas:hover {
  color: #ffffff;
  text-decoration: none;
  padding: 10px;
  margin-bottom: 10px;
}

.Footer .grupo-1 .red-social a{
  display: inline-block;
  text-decoration: none;
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: #20003d;
  margin-right: 10px;
  background-color: transparent;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  transition: all 300ms ease;
}
.Footer .grupo-1 .red-social a:hover{
  background-color: #fff;
  color: rgb(4, 4, 14);
  border-radius: 30px;
}
.Footer .grupo-2{
  background-color: rgb(4, 4, 14);
  padding: 15px 10px;
  text-align: center;
  color: #ffffff77;
}
.Footer .grupo-2 small{
  font-size: 15px;
}
@media screen and (max-width:800px){
  .Footer .grupo-1{
      width: 90%;
      grid-template-columns: repeat(1, 1fr);
      grid-gap:30px;
      padding: 35px 0px;
  }
}


#Creador {
  color: #ffffff5e;
  padding-left: 50px;
  text-decoration: none;
  transition: all 300ms ease;
}
#Creador:hover {
  transition: all 300ms ease;
  color: #ffffffd6;
}